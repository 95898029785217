import React, { useEffect, useRef, useState } from "react";
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, DialogTitle, FormControl, TextField } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js"
import { Editor } from "react-draft-wysiwyg";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../Style/ModalMatch.css"
import '../../assets/scss/customstyle.scss';
import draftToHtml from 'draftjs-to-html';
import { fetchGetEmail } from "../../services/FeeMatchingService"
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import htmlToDraft from 'html-to-draftjs';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import './ClientInfoBarMailView.scss'
import { axiosPost } from "../../utils/api";
import MultipleSelectInput from '../MultipleSelectInput/MultipleSelectInput';
import FileAttachmentModalComponent from '../FileAttachmentModal/FileAttachmentModalComponent/FileAttachmentModalComponent';
import FileAttachmentPreview from '../FileAttachmentPreview/FileAttachmentPreview';
import { axiosGet } from "../../utils/api";
import Button from "../Button/Button";
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { DialogActions, DialogContent } from "@material-ui/core";

const validationSchema = Yup.object().shape({
  editorContent: Yup.string()
    .test(
      'notEmpty',
      'Please provide content for the mail',
      value => value && value.trim().length > 0 // Checking if the editor content is not empty
    )
    .required('Please provide content for the mail')
,
  cc:Yup.string().test("isValidEmails","Please add a valid email address",(value)=>{
    if (!value) 
      return true;
    else{
      const emails=value.split(",").map(email=>email.trim())
      const isValid=emails.every(email=>Yup.string().email().isValidSync(email))
      return isValid
    }
  }),
  file:Yup.array().min(1, 'Please attach at least one file.'),
  selectedValues: Yup.array().min(1, 'Please select at least one provider.'),
  subject: Yup.string().required('Please provide subject for the mail'),
  
});
function ClientInfoBarMailView(props) {
  const currentUserEmail = useSelector(state => state.auth.currentUserData.email);
  const filesSelectedFromAttachmentModal = useSelector(state => state.ui.filesSelectedFromAttachmentModal);
  const [selectedValues, setselectedValues] = useState([]);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const formikRef=useRef();
  // const [valueTo, setValueTo] = useState(props?.client_details?.email)
  const [valueCc, setValueCc] = useState("")
  // const [valueBcc, setValueBcc] = useState("")
  const [valueSubject, setValueSubject] = useState("Catch Up")
  const content = `<p>Hi,</p>\n </br> <p> 
Please find the attached letter of authority for ${props?.client_details?.user?.first_name} ${props?.client_details?.user?.last_name}, could you kindly send us the information detailed below via email. If you are unable to send this via email please post the information to 2/2a Throgmorton Avenue, London, EC2N2DG.</p> \n </br>
<ul>
    <li>The current fund value of the plan.</li>
    <li>The current transfer value of the plan (detailing any penalties).</li>
    <li>All charges associated with the plan, including the Annual Management Charge, Fund Charges, and Platform Charges.</li>
    <li>Name and percentage split of the fund(s) the pension is currently invested in.</li>
    <li>The list of alternative funds available within the pension.</li>
    <li>If an occupational pension plan, details of any protected tax-free cash entitlement at A-day and the fund value at A-day.</li>
    <li>Clarification if there are any guaranteed annuity rates applicable to this policy, and if so, what the guaranteed rates are.</li>
    <li>The full contribution history from both the employee and employer for this plan.</li>
    <li>Retirement Age.</li>
</ul>
<p>If you need any assistance, please do not hesitate to reach out.</p> </br> <p>Kind regards,</p></br>
 <p>
  Lathe & Co
</p>`
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(content))))

  var EmailBody = (`<!DOCTYPE html>\r\n<html>\r\n   <head>\r\n      <title>Page Title</title>\r\n   </head>\r\n<body><div>${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</div></body></html>`)
  const [isLoaSubmitted,setIsLoaSubmitted]=useState(false)
  const userToken = useSelector((state) => state.auth.currentUserData.token);
  const currentlyLoggedUserId = useSelector(state => state.auth.currentUserData.id);
  const onEditorStateChange = (editorState) => { setEditorState(editorState) }
  const { enqueueSnackbar } = useSnackbar();
  const submitMatch = async (values, cc, setSubmitting) => {
    let url = "upload-loa"
    const formData = new FormData()
    let localFileAttachments = [];
    filesSelectedFromAttachmentModal.forEach(item => {
      if (item instanceof File)
      {
          localFileAttachments.push(item)
      }
  })
  localFileAttachments.forEach(fileItem => {
    formData.append('attachments', fileItem, fileItem.name);
})
values.selectedValues.forEach(providerId => {
  if(providerId?.email){
    providerId.email.forEach(email=>formData.append('message_to',email));
  }
})
values.selectedValues.forEach(providerId => {
  formData.append('provider_id', providerId.id);
})
    const mailObj = {
client_id : props?.client_details?.id,
message_body : EmailBody,
message_subject : values.subject,

    }
  cc.forEach(ccItem => {
      formData.append('message_cc', ccItem);
  })  
Object.keys(mailObj).forEach(keys=>{
  formData.append(keys,mailObj[keys])
})
setIsLoaSubmitted(true)
    await axiosPost(url, formData)
      .then((res) => {
        if (res.status) {
          props.afterSuccessfullySent()
          setIsLoaSubmitted(false)
          enqueueSnackbar(res?.data?.message || "Sent SuccessFully ", {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        } else {
          setIsLoaSubmitted(false)
          enqueueSnackbar(res?.data?.message || "Failed to Sent", {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
      }).catch((error) => {
        setIsLoaSubmitted(false)
        enqueueSnackbar(error.message || "Failed to Sent", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
  }
  const extractPlainTextFromEditorState = (editorState) => {
    // Convert the editor content to plain text
    const content = editorState.getCurrentContent();
    return content.getPlainText().trim(); // This will remove empty spaces and return the plain text
  };
  const handleSubmit = (values, { setSubmitting }) => {
    const cc = values.cc.split(',').filter(email => email.trim() !== '');
    // Call your submit function here
    submitMatch(values, cc,setSubmitting);

    
  };
  useEffect(()=>{
    formikRef.current.setFieldValue("file",filesSelectedFromAttachmentModal) 
  },[filesSelectedFromAttachmentModal])
const closeAttachmentModal = () => {
  setOpenAttachmentModal(false);
}

  return (
    <Formik
      initialValues={{
        selectedValues: [],
        cc: '',
        subject: 'LOA Document',
        file:[],
        editorContent: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(content)))
      }}
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <DialogTitle  style={{position:"sticky",top:0,left:0,padding: '10px',
      backgroundColor: 'white',zIndex: 1000,display:"flex",justifyContent:"space-between"}}>
<span>Upload LOA</span> <span><CloseIcon className="icon" style={{cursor:"pointer"}} onClick={()=>props.close()}/></span>
</DialogTitle>
          <DialogContent style={{overflowY:"unset"}}>
              <Grid container spacing={3}>
              
                <Grid item xs={12} md={10}>
                  {/* Provider Select Input */}
                  <MultipleSelectInput
                    label="Select Provider"
                    setselectedValues={(val) => setFieldValue('selectedValues', val)}
                    selectedValues={values.selectedValues}
                    options={props?.providerOptions}
                    required
                    margin="0px"
                    hiddenLabel={false}
                    displayEmpty={false}
                    width={747}
                    emptyMessage="Loading Providers"
                    placeholder="Select Provider"
                  />
                  <ErrorMessage name="selectedValues" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Field
                    name="subject"
                    as={TextField}
                    label="Subject"
                    fullWidth
                    rows={1}
                  />
                  <ErrorMessage name="subject" component="div" style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Field
                    name="cc"
                    as={TextField}
                    label="CC"
                    fullWidth
                    rows={1}
                    helperText="Note:Enter multiple email addresses, separated by commas."
                  />
                  <ErrorMessage name="cc" component="div" style={{ color: 'red' }} />
                </Grid>

                <Grid item xs={12} md={10}>
                <div style={{marginLeft:"8px"}}><Button
                          type="submit"
                          width="157px"
                          bgColor="secondary"
                          onClick={() => setOpenAttachmentModal(true)}
                        >
                          <AttachFileIcon />
                          Attach {filesSelectedFromAttachmentModal.length > 0 ? 'more' : 'file'}
                  </Button></div>
                  <FileAttachmentModalComponent
                    open={openAttachmentModal}
                    handleClose={() => setOpenAttachmentModal(false)}
                    size="sm"
                    showCloseIcon
                    enableCapDrive={false}
                    accept=".doc, .docx,.pdf"
                  />
                  <ErrorMessage name="file" component="div" style={{ color: 'red' ,margin:"8px"}} />
                  {filesSelectedFromAttachmentModal?.length > 0 && (
                    <FileAttachmentPreview
                      files={filesSelectedFromAttachmentModal}
                  showCloseIcon={true}
                      showDownloadIcon={false}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={10}>
              <ErrorMessage name="editorContent" component="div" style={{ color: 'red' }} />
              <Editor
              defaultEditorState={values.editorContent}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(state) => {
                  onEditorStateChange(state)
                  const plainText = extractPlainTextFromEditorState(state);
                  setFieldValue('editorContent', plainText);
                }}
              /></Grid></Grid>  </DialogContent> <div style={{position:"sticky",bottom:0,left:0,padding: '10px',
                backgroundColor: 'white',zIndex: 1000,padding:"10px",display:"flex",justifyContent:"space-between"}}>
                <Button
                          onClick={() => {
                            props.close();
                            props.afterSuccessfullySent();
                          }}
                          width="400px"
                          className="matchCancelButton btn"
                        >
                          DISCARD
                        </Button>
                        <Button
                          type="submit"
                          width="400px"
                          bgColor="secondary"
                          disabled={isLoaSubmitted}
                          className="matchSubmitButton btn"
                        >
                          Send {isLoaSubmitted&&<CircularProgress size={12} style={{ color: "#32C2D9",marginLeft:"3px" }}/>}
                        </Button>
              </div>
        </Form>
      )}
    </Formik>

  )
}
export default ClientInfoBarMailView;